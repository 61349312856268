import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Axios from 'axios';
import mixpanel from "mixpanel-browser";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import "./styles.scss";

import MultiStepForm from "../../../components/MultiStepForm"
import Loader from "../../../components/Loader"
import LogoImg from "../../../images/shw-logo.png"
import LTLogoImg from "../../../images/LT-logo.png"
import VerifiedImg from "../../../images/verified.svg"
import { Header } from '../../../components/Header';

import Logo1 from "../../../images/CA_Accredited_Shield-sm-color.png"
import Logo2 from "../../../images/logo-Home-Warranty.png"
import Logo3 from "../../../images/top-10-sites.png"
import Logo4 from "../../../images/editors-choice-2021-200px-color.png"
import Logo5 from "../../../images/logo-certified-partner.png"
import Logo6 from "../../../images/top-10-color.png"
import Logo7 from "../../../images/shopper-approved.png"

import AsSeenOnLogo1 from '../../../images/A&E.png'
import AsSeenOnLogo2 from '../../../images/fox-logo.png'
import AsSeenOnLogo3 from '../../../images/lifetime-logo.png'
import AsSeenOnLogo4 from '../../../images/history-logo.jpeg'

import ACImg from "../../../images/AC.svg"
import HeatImg from "../../../images/heat.svg"
import WaterHeaterImg from "../../../images/water-heat.svg"
import OvenImg from "../../../images/oven-image.svg"
import PlumbingImg from "../../../images/plumbing.svg"
import WasherImg from "../../../images/washer.svg"
import QuotesImg from "../../../images/quotes.png"
import ReportImg from "../../../images/claim.svg"
import ProfessionalsImg from "../../../images/professional.svg"
import ToolsImg from "../../../images/repair.svg"
import CallImg from "../../../images/call.svg"
import CallWhiteImg from "../../../images/icon-phone-white.svg"
import APolicyImg from "../../../images/APolicy.svg"
import HouseImg from "../../../images/medieval-house.svg"
import UpImg from "../../../images/up.svg"
import DownImg from "../../../images/down.svg"
import Helmet from "react-helmet";

import getCookie from '../../../helpers/getCookie';
import getParameterByName from '../../../helpers/getParameterByName';

const axios = Axios.create();
axios.defaults.baseURL = process.env.GATSBY_API_URL;

mixpanel.init(process.env.GATSBY_MIXPANEL_TOKEN);

const LEAD_ID = 'ab-template-affiliate-new-mobile-form';

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
  arrows: false
}

const Page = (props) => {
  const [data, setData] = useState({});
  const [expanded, setExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const show_as_seen_on = process.env.SHOW_AS_SEEN_ON || false;

  const quoteFormSteps = [
    {
      image: HouseImg,
      title: 'Enter your zip code to get a quote',
      fields: [
        {
          label: 'Zip Code',
          name: 'zipCode',
          type: 'number',
          inputmode: 'numeric',
          tooltipText: 'Coverage plan pricing can vary by location. Enter your zip code to get an accurate quote.',
          hideLabel: true,
          initialValue: data.zipCode,
          otherProps: {
            maxLength: 5,
            autoComplete: 'off',
          },
          validate: (value) => {
            return new Promise((resolve) => {
              axios.get(`/zip_lookup/${value}`)
                .then((response) => {
                  if (response.data) {
                    const { city, state } = response.data;
                    setData({
                      ...data,
                      city,
                      state
                    })
                    resolve(true)
                  }
                  resolve(false)
                })
                .catch((error) => {
                  console.log(error);
                  resolve(false)
                })
            })

          }
        }
      ],
      nextBtnLabel: 'GET STARTED'
    },
    {
      title: 'Now, please enter your first and last name.',
      fields: [
        {
          label: 'First Name',
          name: 'firstName',
          type: 'text',
          tooltipText: 'This information will be used to confirm quote details if needed.',
          validate: value => !!value
        },
        {
          label: 'Last Name',
          name: 'lastName',
          type: 'text',
          tooltipText: 'This information will be used to confirm quote details if needed.',
          validate: value => !!value
        },
      ],
    },
    {
      title: 'Last step, let’s get your phone and email address.',
      fields: [
        {
          label: 'Phone Number',
          name: 'phone',
          type: 'text',
          maskFormat: '(999) 999-9999',
          stickyLabel: '1',
          tooltipText: 'This information will be used to confirm quote details if needed.',
          validate: value => !!value
        },
        {
          label: 'Email Address',
          name: 'email',
          type: 'text',
          tooltipText: 'Your email will only be used for communication regarding your home warranty plan quote details.',
          validate: value => !!value && (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
        },
      ],
      nextBtnLabel: 'GET QUOTE'
    },
  ]

  const trackCTA = () => {
    mixpanel.track("cta_clicked", {
      version: LEAD_ID,
      affiliateCode: data.discountcode,
      origin: "header"
    });
  }

  const generateWhispScriptTag = (pid, referredFrom, subid, subid2) => {
    var whispScriptTag = document.createElement('script')
    Object.entries({
      type: 'text/javascript',
      id: 'taptext-lib',
      'data-pid': pid,
      src: 'https://text.whisp.io/scripts/taptext_lib.js',
      'data-referred_from': referredFrom,
      'data-subid': subid,
      'data-subid2': subid2,
    }).forEach(([key, value]) => {
      whispScriptTag.setAttribute(key, value);
    });

    document.getElementsByTagName('head')[0].appendChild(whispScriptTag);
  }

  const FixedCallButton = () => {
    let params = new URLSearchParams(props.location.search);
    let subid = params.get('subid')
    let subid2 = params.get('subid2')

    if (discountcode === '12345') {
      generateWhispScriptTag('ce9d6b49-f410d3b6', '12345', subid, subid2)
      return <div />
    } else if (discountcode === 'smleads') {
      // {affiliate_id: 'smleads', nickname: 'smleads'}
      generateWhispScriptTag('ce9d6b49-f03b34ce', 'SmLeads', subid, subid2)
      return <div />
    } else if (discountcode === '74af2d') {
      // {affiliate_id: '74af2d', nickname: 'LIQD'}
      generateWhispScriptTag('ce9d6b49-f0371869', 'LIQD', subid, subid2)
      return <div />
    } else if (discountcode === 'ffdc00') {
      // {affiliate_id: 'ffdc00', nickname: 'NAICOMP'}
      generateWhispScriptTag('ce9d6b49-fc69a10c', 'NAICOMP', subid, subid2)
      return <div />
    } else if (discountcode === '47629f') {
      // {affiliate_id: '47629f', nickname: 'NAIM'}
      generateWhispScriptTag('ce9d6b49-f6ef5318', 'NAIM', subid, subid2)
      return <div />
    } else {
      return <div className="call-now">
              <a href={`tel:${affiliate_phone_number && affiliate_phone_number.replace(/\D/g,'')}`} onClick={trackCTA}>
                <img src={CallWhiteImg} alt="Call now for a free quote" />
              </a>
            </div>
    }
  }

  const handleSubmit = (form) => {
    return new Promise((resolve) => {
      const {
        firstName,
        lastName,
        email,
        phone,
        zipCode,
      } = form;

      const {
        id,
        discountcode,
        subid,
        subid2,
        gclid,
        conaffid,
        city,
        state
      } = data;

      let payload = {
        customer: {
          first_name: firstName,
          last_name: lastName,
          email,
          customer_phone: phone,
          subid,
          leadid: LEAD_ID,
          partner_id: id,
        },
        property: {
          city,
          state,
          zip_code: zipCode
        },
        discountcode,
        subid,
        subid2,
        leadid: LEAD_ID,
        partner_id: id,
        conaffid,
        gclid,
        friendbuy_referral_code: getCookie('shw-friendbuy-referral-code'),
      };

      mixpanel.track("Submit the form", {
        version: LEAD_ID,
        affiliateCode: discountcode,
        origin: "hero"
      });

      axios.post('/quotes/quote', payload)
        .then((response) => {
          if (response && response.data) {
            const { status } = response.data;
            if (status === 'success') {
              toast.success('Submitted successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              const values = [true, false, false, false, false]
              if (false && (process.env.GATSBY_ALWAYS_REDIRECT_TO_QUESTIONNAIRE === 'true' || values[Math.floor(Math.random() * values.length)])) {
                mixpanel.track("Go to questionnaire", {
                  version: LEAD_ID,
                  affiliateCode: discountcode
                });
                window.location.href = `/questionnaire?userid=${response.data.customer_id}&affiliateCode=${discountcode}`
              } else {
                window.location.replace(`${process.env.GATSBY_REDIRECT_URL}/purchase?customer_id=${response.data.customer_id}&newversion=${LEAD_ID}`)
              }
            }

            if (status === 'please_call') {
              setMessage(`Please call us for more details and your discounted rate at <strong>1-855-267-3532<strong>`);
            }
            if (status === 'restricted_state') {
              setMessage(`Sorry, we currently don't offer coverage in ${state} - please stay tuned!`);
              const redirectUrl = `${process.env.GATSBY_RESTRICTED_REDIRECT_URL}/location?brand=selhw&s=31&zip=${zipCode}&first_name=${firstName}&last_name=${lastName}&email=${email}&phone=${phone}`;
              window.location.replace(redirectUrl)
            }
          }
          resolve(true)
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          resolve(false)
        });
    })
  }

  useEffect(() => {
    let params = new URLSearchParams(props.location.search);
    const discountCode = props.params.discountcode;

    axios.get(`/v2/partners/${discountCode}`)
      .then((response) => {
        setLoading(false);
        const zipCode = params.get('zip_code');
        setData({
          ...response.data.partner,
          discountcode: discountCode,
          subid: params.get('subid') || '0',
          subid2: params.get('subid2') || '0',
          gclid: params.get('gclid'),
          conaffid: params.get('conaffid'),
          zipCode,
        });
        if (zipCode) {
          document.getElementsByClassName('continue-btn')[0].disabled = false;
          document.getElementsByClassName('continue-btn')[0].click()
        }


      })
      .catch((error) => {
        console.log('partner api error', error);
        if (error.response.status === 404) {
          window.location.replace(process.env.GATSBY_PLANS_URL)
        }
      })

    mixpanel.track("Visit the affiliate page", {
      version: LEAD_ID,
      affiliateCode: discountCode
    });
  }, []);

  useEffect(() => {
    let params = new URLSearchParams(props.location.search);
    // Set referred_from localStorage for consumption by other SHW sites
    const urlParts = window.location.host.split('.')
    const domain = urlParts.slice(0).slice(-(urlParts.length === 4 ? 3 : 2)).join('.')

    if (params.get('gclid')) {
      document.cookie = 'shw-referred-from=google; path=/; max-age=31536000; domain=' + domain
    } else {
      document.cookie = 'shw-referred-from=' + data.nickname + '; path=/; max-age=31536000; domain=' + domain
    }
  }, [data.nickname]);

  useEffect(() => {
    if(getParameterByName('utm_source') == 'friendbuy') {
      const urlParts = window.location.host.split('.')
      const domain = urlParts.slice(0).slice(-(urlParts.length === 4 ? 3 : 2)).join('.')
      document.cookie = 'shw-friendbuy-referral-code=' + getParameterByName('referralCode') + '; path=/; max-age=31536000; domain=' + domain
    }
  }, []);

  const {
    affiliate_phone_number,
    banner_text_1,
    affiliate_header_text_1,
    affiliate_second_message,
    quote_promo_text_1,
    quote_promo_text_2,
    quote_promo_text_3,
    discountcode,
    // affiliate_image_url
  } = data;

  const affiliate_image_url = '../../../images/william-shatner---mobile-hero-image.png'

  const mobileLogosSetting = {...sliderSettings, dots: false}

  var display_privacy_policy = process.env.GATSBY_DISPLAY_PRIVACY_POLICY;
  if (display_privacy_policy != null && display_privacy_policy.length) {
    display_privacy_policy = display_privacy_policy.split(",").map(function(item) {
      return item.trim().toLowerCase();
    });
  } else {
    display_privacy_policy = []
  }
  const displayPrivacyPolicy = display_privacy_policy.includes(discountcode)

  if (loading) {
    return (
      <div className="loader-container">
        <Loader size={150} color="#1F497D" />
      </div>
    )
  }

  return (
    <div className="affiliate-mobile">
      <Helmet>
        <title>Select Home Warranty</title>
      </Helmet>

      <Header logo={ LogoImg }
        phoneNumber={ affiliate_phone_number }
        countDownData={ data }
        callImage={ CallImg }
        trackCTA={ trackCTA }
        ></Header>

      <FixedCallButton />

      <div className="affiliate-alert-2">
        <img src={VerifiedImg} alt="Verified" height="26px" width="26px" />
        {/* "I trust Select Home Warranty to protect my home, and you should too." - W. Shatner */}
      </div>

      <div className="hero-split">
        <div className="bg-img"></div>
        <div className="form-root">
          <div className="form-info">
            {/* <h1>{affiliate_header_text_1}</h1> */}
            <h1>"I trust Select Home Warranty to protect my home, and you should too."</h1>
            <h1>
              William Shatner
            </h1>
          </div>
          <div className="mobile-form">
            <MultiStepForm discountCode={discountcode} steps={quoteFormSteps} onSubmit={handleSubmit} showModal={true} showProgress={true} isMobile={true}/>
          </div>
        </div>
      </div>

      {
        show_as_seen_on == 'true' ? 
          <section className="as-seen-on">
            <small className='as-seen-on__text'> <b> AS SEEN ON </b> </small>
            <div className="logos-mobile">
              <Slider {...mobileLogosSetting}>
                <div className="slide">
                  <div className="logo-item"><img src={AsSeenOnLogo1} alt="" height='50px' /></div>
                </div>
                <div className="slide">
                  <div className="logo-item"><img src={AsSeenOnLogo2} alt="" height='50px' /></div>
                </div>
                <div className="slide">
                  <div className="logo-item"><img src={AsSeenOnLogo3} alt="" height='50px' /></div>
                </div>
                <div className="slide">
                  <div className="logo-item"><img src={AsSeenOnLogo4} alt="" height='50px' /></div>
                </div>
              </Slider>
            </div>
          </section> :
          null
      }

      <div className="logos">
        <div className="logos-container">
          <div className="logo-item"><img src={Logo1} alt="" /></div>
          <div className="logo-item"><img src={Logo2} alt="" /></div>
          <div className="logo-item"><img src={Logo4} alt="" /></div>
          <div className="logo-item"><img src={Logo3} alt="" /></div>
          <div className="logo-item"><img src={Logo5} alt="" /></div>
          <div className="logo-item"><img src={Logo6} alt="" /></div>
          <div className="logo-item"><img src={Logo7} alt="" /></div>
          {(discountcode && discountcode.toLowerCase() === 'ltree') && (
            <div className="logo-item"><img src={LTLogoImg} height="65" alt="" /></div>
          )}
        </div>
      </div>

      <div className="logos-mobile">
        <Slider {...mobileLogosSetting}>
          <div className="slide">
            <div className="logo-item"><img src={Logo2} alt="" /></div>
          </div>
          <div className="slide">
            <div className="logo-item"><img src={Logo4} alt="" /></div>
          </div>
          <div className="slide">
            <div className="logo-item"><img src={Logo3} alt="" /></div>
          </div>
          <div className="slide">
            <div className="logo-item"><img src={Logo5} alt="" /></div>
          </div>
          <div className="slide">
            <div className="logo-item"><img src={Logo6} alt="" /></div>
          </div>
          <div className="slide">
            <div className="logo-item"><img src={Logo7} alt="" /></div>
          </div>

        </Slider>
      </div>

      <div className="view-more" onClick={() => setExpanded(!expanded)}>
        View {expanded ? 'Less' : 'More'}
        <img src={expanded ? UpImg : DownImg} alt="" />
      </div>

      {expanded && (
        <>
          <div className="costs">
            <div className="container text-center">
              <h2>Avoid Unexpected Repair Costs</h2>
              <p>Shield your wallet from unexpected costs of repairing or<br />replacing a home appliance.</p>
              <div className="unexpected-repair-costs col-sm-10 col-sm-centered">
                <div className="unexpected-repair-cost"><img src={ACImg} alt="" />
                  <h4>AC, Average Repair</h4>
                  <table>
                    <tbody>
                      <tr>
                        <td>Avg. repair cost:</td>
                        <td>$350</td>
                      </tr>
                      <tr>
                        <td>Avg. replace cost:</td>
                        <td>$5500</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="unexpected-repair-cost"><img src={HeatImg} alt="" />
                  <h4>Heat</h4>
                  <table>
                    <tbody>
                      <tr>
                        <td>Avg. repair cost:</td>
                        <td>$350</td>
                      </tr>
                      <tr>
                        <td>Avg. replace cost:</td>
                        <td>$4500</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="unexpected-repair-cost"><img src={WaterHeaterImg} alt="" />
                  <h4>Water Heater</h4>
                  <table>
                    <tbody>
                      <tr>
                        <td>Avg. repair cost:</td>
                        <td>$275</td>
                      </tr>
                      <tr>
                        <td>Avg. replace cost:</td>
                        <td>$1500</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="unexpected-repair-cost"><img src={OvenImg} alt="" />
                  <h4>Oven</h4>
                  <table>
                    <tbody>
                      <tr>
                        <td>Avg. repair cost:</td>
                        <td>$285</td>
                      </tr>
                      <tr>
                        <td>Avg. replace cost:</td>
                        <td>$900</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="unexpected-repair-cost"><img src={PlumbingImg} alt="" />
                  <h4>Plumbing System</h4>
                  <table>
                    <tbody>
                      <tr>
                        <td>Avg. repair cost:</td>
                        <td>$300</td>
                      </tr>
                      <tr>
                        <td>Avg. replace cost:</td>
                        <td>$3500</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="unexpected-repair-cost"><img src={WasherImg} alt="" />
                  <h4>Washer</h4>
                  <table>
                    <tbody>
                      <tr>
                        <td>Avg. repair cost:</td>
                        <td>$285</td>
                      </tr>
                      <tr>
                        <td>Avg. replace cost:</td>
                        <td>$650</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="testimonials-slider text-center">
            <div className="slide-wrapper"><img src={QuotesImg} className="quotation-mark" alt="" />
              <Slider {...sliderSettings}>
                <div className="slide">
                  <div className="quote">I am lucky to have received a warranty from Select Home Warranty at my closing. The cost to repair my central AC was $60 instead of $1200! Thank you.</div>
                  <div className="quote-author">Josh H, Boston MA</div>
                </div>
                <div className="slide">
                  <div className="quote">I spoke with Select Home on a Sunday night for my water heater that would not shut off. It took less than 24 hours to get a dealer to my house who diagnosed the problem and fixed a bad valve. Service like this makes me very happy.</div>
                  <div className="quote-author">D Mai, Bumont MS</div>
                </div>
                <div className="slide">
                  <div className="quote">I am buying a new house and it has already been decided by my wife that the home warranty contract will go to Select only and no one else. This firm belief is the result of the excellent service and support provided by Select in the current home.</div>
                  <div className="quote-author">Ed H, Middletown DE</div>
                </div>
              </Slider>
            </div>
          </div>


          <div className="peace-of-mind container text-center">
            <h2>We Provide Peace of Mind</h2>
            <p>You never know when a heat or plumbing system,<br />appliance or garage door will break</p>
            <div className="tools row">
              <div className="tool"><img src={ReportImg} alt="" />
                <h3>1. Report a claim</h3>
                <p>When something happens, call us or contact us online. Our team will open a claim 24/7.</p>
              </div>
              <div className="tool"><img src={ProfessionalsImg} alt="" />
                <h3>2. We assign a local professional</h3>
                <p>Finding the right professional is difficult. We have a large network of professionals throughout the country.</p>
              </div>
              <div className="tool"><img src={ToolsImg} alt="" />
                <h3>3. Repair</h3>
                <p>We’ll repair your covered item. You pay a minimal service fee. Simple as that.</p>
              </div>
            </div>
          </div>


          <footer className="desktop">
            <div className="footer-desktop">
              <div className="top">
                <div className="links">
                  <div>
                    <div><a href="/">Plans</a></div>
                    <div><a href="/">Real Estate Center</a></div>
                    <div><a href="/">Terms</a></div>
                    <div><a href="/">Blog</a></div>
                  </div>
                  <div>
                    <div><a href="/">Homeowners</a></div>
                    <div><a href="/">Contractors</a></div>
                    {displayPrivacyPolicy && (
                      <div><a href="https://www.selecthomewarranty.com/privacy/">Privacy Policy</a></div>
                    )}
                    <div><a href="/">Contact Us</a></div>
                  </div>
                  <div>
                    <div><a href="/">Buyers & Sellers</a></div>
                    <div><a href="/">Submit Claim</a></div>
                    <div><a href="/">FAQs</a></div>
                  </div>

                </div>
                  By requesting a quote from Select Home Warranty, you provide your consent for Select Home Warranty to contact you via phone, email, text, pre-recorded messages as well as your mobile number using automated technology regarding select offers.
                </div>
              <div className="right">
                <img src={APolicyImg} alt="Select Home Warranty" className="footer-logo" />
              </div>
            </div>
          </footer>

          <footer className="mobile">
            <div className="top">
              <div className="links">
                <div>
                  <div><a href="/">Plans</a></div>
                  <div><a href="/">Terms</a></div>
                </div>
                <div>
                  <div><a href="/">Homeowners</a></div>
                  <div><a href="/">Privacy</a></div>
                </div>
                <div>
                  <div><a href="/">Buyers & Sellers</a></div>
                  <div><a href="/">FAQs</a></div>
                </div>
                <div>
                  <div><a href="/">Real Estate Center</a></div>
                  <div><a href="/">Blog</a></div>
                </div>
                <div>
                  <div><a href="/">Contractors</a></div>
                  <div><a href="/">Contact Us</a></div>
                </div>
                <div>
                  <div><a href="/">Submit Claim</a></div>
                </div>

              </div>
              <div className="footer-img">
                <img src={APolicyImg} alt="Select Home Warranty" className="footer-logo" />
              </div>
              <div className="footer-des">
                By requesting a quote from Select Home Warranty, you provide your consent for Select Home Warranty to contact you via phone, email, text, pre-recorded messages as well as your mobile number using automated technology regarding select offers.
              </div>
            </div>
          </footer>

        </>
      )}
      <ToastContainer />
    </div>
  )
}

export default Page
